import { css } from '@emotion/react';
import { useState, ChangeEvent, FocusEvent } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { HeadInner } from '../../components/Head';
import { PostConversionInfo, handlePostWithTrackingCV } from '../../util/post';
import { Layout } from '../../components/layout';
import { RegistrationLayout } from '../../components/forms/RegistrationLayout';
import { colors } from '../../theme/colors';

export const Head = () => (
  <HeadInner path='/registration' title='口座情報登録' noindex />
);

const RegistrationPartnerAccountant = () => {
  const [agree, setAgree] = useState(false);

  const location = useLocation();

  const env = process.env.CONTEXT;

  const portalId = process.env.HUBSPOT_ID ? process.env.HUBSPOT_ID : '';
  const formId =
    env === 'dev' || env === 'stg'
      ? '1703ea1c-0d1d-4da5-8d0c-a5bcd9da15dc'
      : 'e20b7f13-0986-4b2d-ba7d-16757b2ee337';

  const [form, setForm] = useState<PostConversionInfo>({
    email: '',
    lastname: '',
    firstname: '',
    company: '',
    deposit_type: '',
    bank: '',
    branch: '',
    account_number: '',
    account_holder: '',
    pageUri: location.href,
    registration: true,
    request_document: false,
    service_codes: ['prepaid_card'],
  });

  const handleAgree = () => {
    setAgree(!agree);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({
      ...form,
      phone: e.currentTarget.value.replace(/-/g, ''),
    });
  };

  const requiredList = [
    'company',
    'email',
    'lastname',
    'firstname',
    'deposit_type',
    'bank',
    'branch',
    'account_number',
    'account_holder',
  ];

  const onPostButtonClick = async () => {
    await handlePostWithTrackingCV(
      form,
      portalId,
      formId,
      document.title,
      'partner-accountant'
    );
    navigate('/registration-partner-accountant/complete/');
  };

  const links = [
    {
      name: `paildパートナープログラム`,
      path: `/registration-partner-accountant/`,
    },
  ];

  return (
    <Layout
      header='simple'
      background='simple'
      footer='simple'
      isBottomMenu={false}
      links={links}
    >
      <RegistrationLayout
        form={form}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        onBlur={handlePhoneBlur}
        agree={agree}
        handleAgree={handleAgree}
        requiredList={requiredList}
        onPostButtonClick={onPostButtonClick}
        isPartnerAccountant
      >
        <h2 css={RegistrationTitleStyle}>paildパートナープログラム</h2>
        <div css={RegistrationDescriptionStyle}>
          <p>
            このページは、パートナー企業様を対象としたpaild紹介プログラムのお申込みページです。
            <br />
            フォームより必要事項をご入力の上、ご送信ください。
          </p>
        </div>
      </RegistrationLayout>
    </Layout>
  );
};

const RegistrationTitleStyle = css({
  color: colors.black,
  fontSize: 40,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const RegistrationDescriptionStyle = css({
  p: { fontSize: 16, lineHeight: 2, letterSpacing: 0.05 },

  '@media(max-width: 768px)': {
    br: { display: 'none' },
    p: { fontSize: 14 },
  },
});

export default RegistrationPartnerAccountant;
